/* width */
::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $separator-color;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $gradient-color-1;
  cursor: pointer;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gradient-color-3;
}

.page-content {
  padding: 24px;
  margin: 0;
  background: $button-text-color;
}

.page-layout-non-collapsed {
  margin-left: 235px;
  min-height: 100vh;
  padding: 0 24px 24px;
}

.page-layout-collapsed {
  margin-left: 80px;
  min-height: 100vh;
  padding: 0 24px 24px;
}
