.ant-layout-header {
  padding-inline: 15px !important;
}
.ant-layout .ant-layout-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 40;
}

.top-navbar {
  // position: sticky !important;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  background-color: white !important;
}

.left-menu {
  float: left;
}

.right-menu {
  float: right;
  margin: 0 10px;
}

.sidebar-collapse-button {
  padding: 5px 10px;
  cursor: pointer;
}

.notification-popup {
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 8%), 0 3px 6px -4px rgb(0 0 0 / 12%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
  min-width: 250px;
  min-height: 300px;

  .ant-card-head-title {
    padding: 5px 0;
  }

  .ant-card-body {
    padding: 0 24px;
  }
}
