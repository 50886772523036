.sidebar {
  width: $sidebar-width;
  // height: 100vh;
  z-index: 50;
  overflow-y: auto;
  background-color: white !important;
}

.sidebar-top-section {
  padding: 22px;
  text-align: center;
}

.ant-menu-dark {
  background-color: $dark-sidebar-background-color !important;
}

.ant-menu-light {
  background-color: $light-sidebar-background-color !important;

  .ant-menu-item-selected {
    background-color: #151529 !important;
  }

  .ant-menu-item-group {
    border-bottom: 4px solid rgba(5, 5, 5, 0.06);
    .ant-menu-item-group-title {
      border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    }
  }

  .ant-menu-item-group:first-child {
    border-top: 4px solid rgba(5, 5, 5, 0.06);
  }
}

.sidebar .ant-menu-inline-collapsed {
  .ant-menu-item-group-title {
    display: none;
  }
  .anticon {
    font-size: 20px !important;
  }
}

.ant-menu-item .active {
  font-weight: 600;
  color: $theme-color-2 !important;
  font-size: 14px;
}

.ant-layout-sider {
  background-color: $sidebar-bg !important;
}

.ant-menu-item {
  a {
    color: #a3a6b7 !important;
  }
}

.logo {
  color: #fff;
}

.ant-menu-item .active {
  color: #fff !important;
}