$font-primary: 'Inter', sans-serif !important;
$bg-color-btn: #4b38b3;

a {
  text-decoration: none !important;
}

body {
  font-family: $font-primary;
}

:focus-visible {
  outline: -webkit-focus-ring-color auto 0;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 10px;
}

.lds-ellipsis div {
  position: absolute;
  /*top: 27px;*/
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(19px, 0);
  }
}

.cursor-pointer {
  cursor: pointer;
}

.float-right {
  float: right;
}

.ant-layout {
  background-color: #f2f2f7;
}

.ant-layout-content {
  box-shadow: 0 3px 3px rgb(56 65 74 / 10%);
  border-radius: 5px;
}

.form-group {
  label {
    display: block;
    margin-bottom: 8px;
    color: #000;
    font-size: 14px;
    font-weight: 600;
  }
}

.form-control {
  height: 50px;
  border-radius: 4px !important;
  width: 100% !important;
  font-size: 16px !important;
  border: 1px solid #ced4da !important;
  padding: 10px 15px !important;
  font-family: $font-primary;
}

.form-control:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-group {
  margin-bottom: 20px;
}

.ck.ck-editor__editable_inline {
  height: 400px !important;
}

.common-btn {
  background-color: $bg-color-btn;
  padding: 0px 40px;
  font-size: 16px;
  border: 0;
  color: #fff !important;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s all;
  font-family: $font-primary;
  display: inline-block;
  border: 1px solid $bg-color-btn;
  height: 50px;
  line-height: 50px;
}

.common-btn:hover {
  background-color: #403098;
}

.outline-btn {
  background-color: #fff;
  color: #000 !important;
  border: 1px solid #151529;
  margin-right: 20px !important;
}

.margin-right {
  margin-right: 20px !important;
}

.outline-btn:hover {
  color: #fff !important;
  border: 1px solid #403098;
}

h1 {
  font-family: $font-primary;
}

.ant-breadcrumb {
  font-family: $font-primary;
}

.ant-menu {
  font-family: $font-primary;
}

.ant-table-thead th {
  background-color: #efefef !important;
}

.card-title {
  padding: 0px 0px 0px;
  margin-top: 0;
  font-family: $font-primary;
  font-size: 24px;
  font-weight: 600;
}

.active-token {
  background-color: #c7ffe2;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  width: 100px;
  text-align: center;
}

.inactive-token {
  background-color: #ffcaca;
  padding: 5px 20px;
  display: inline-block;
  border-radius: 50px;
  font-size: 12px;
  font-weight: 600;
  width: 100px;
  text-align: center;
}

.actions-wrap {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 20px;
    }
    li:last-child {
      margin-right: 0;
    }
  }
}

.view-icon {
  font-size: 20px;
}

.edit-icon {
  font-size: 20px;
  color: $bg-color-btn;
}

.delete-icon {
  font-size: 20px;
  color: red;
}

.textarea-height {
  height: auto !important;
}

.wrapper-radio {
  label {
    display: inline-block !important;
  }
}

.check-icon {
  font-size: 20px;
  position: relative;
  top: -1px;
  margin-right: 5px;
}

.required-feild {
  color: red;
}

.ant-radio-wrapper .ant-radio-inner {
  top: 3px !important;
  position: relative;
}

.top-heading {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 30px;

  .col-md-6 {
    width: 50%;
  }

  .text-right {
    float: right;
  }
}

// =======================================BANNER COMPONENT===========================

// ===================================BANNER COMPONENT===============================

.banner-img-wrap {
  width: 180px;
  height: 90px;
  border: 1px solid #ced4da;
  img {
    width: 100%;
    height: 100%;
  }
}

.ant-btn-default .anticon svg {
  position: relative !important;
  top: -3px;
}

.min-height-wrapper {
  min-height: 300px;
}

.delete-btn {
  background-color: transparent;
  border: 0;
}

.ant-btn-primary {
  background-color: $bg-color-btn !important;
}

.ant-btn-primary:hover {
  background-color: $bg-color-btn !important;
}

select {
  height: 50px;
}

.upload-btn {
  background-color: $bg-color-btn !important;
  border: 1px solid $bg-color-btn !important;
  color: #fff !important;
  transition: 0.5s all;
}
.upload-btn:hover {
  background-color: $bg-color-btn;
  transition: 0.5s all;
}

.list-images {
  ul {
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      margin-right: 20px;

      .wrapper-list-img {
        width: 125px;
        height: 125px;
        overflow: hidden;
        position: relative;
        border-radius: 5px;

        img {
          width: 100%;
          height: 100%;
        }

        button {
          padding: 0;
          border: 0;
          background-color: transparent;
          position: absolute;
          right: 5px;
          color: #fff;
          font-size: 24px;
          top: 1px;
          line-height: initial;
        }
      }
    }
  }
}

.form-select:focus {
  box-shadow: none !important;
  outline: none !important;
}

.ant-picker .ant-picker-input > input {
  font-size: 16px;
}

.error-msg {
  color: red;
  font-size: 13px;
  display: block;
  margin-top: 5px;
}

.ant-pagination .ant-pagination-item-active {
  border-color: $bg-color-btn;
}

.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  border-color: $bg-color-btn;
  background-color: $bg-color-btn;
}

.sidebar-top-section {
  background-color: #252546;
  padding: 20px 22px;
}

.ant-breadcrumb {
  margin-top: 80px !important;
}

.input-box-wrapper {
  position: relative;

  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0px 24px;
    background-color: $bg-color-btn;
    border: 0;
    color: #fff;
    border-radius: 0px 5px 5px 0;
  }

  button:hover {
    background-color: $bg-color-btn;
  }

  button:before {
    content: '';
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-right: 7px solid $bg-color-btn;
    border-bottom: 8px solid transparent;
    position: absolute;
    left: -7px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.filter-wrapper {
  .submit-filter {
    height: 50px;
    background-color: $bg-color-btn;
    padding: 0px 30px;
    font-size: 16px;
    border: 0;
    color: #fff !important;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.5s all;
    font-family: 'Inter', sans-serif !important;
    display: inline-block;
    border: 1px solid $bg-color-btn;

    span {
      margin-left: 5px;
    }
  }
}

.file-size {
  font-size: 13px !important;
  color: rgb(143, 143, 143);
}

.ant-spin .ant-spin-dot {
  font-size: 18px;
}

.ant-spin .ant-spin-dot-item {
  background-color: #ffffff;
}

.spin-loader {
  margin-right: 8px;
  position: relative;
  top: 1px;
}

.spin-loader-table .ant-spin-dot-item {
  background-color: #000;
}

.err-msg {
  color: #d22c2e;
  font-size: 14px;
  margin-top: 4px;
}

.action-wrapper {
  .delete-btn {
    padding: 0;
  }
}
.ant-select-selector {
  width: 100% !important;
  height: 50px !important;

  .ant-select-selection-item {
    line-height: 50px !important;
    font-size: 16px;
  }
  .ant-select-selection-search-input {
    height: 50px !important;
  }
  .ant-select-selection-placeholder {
    line-height: 50px !important;
  }
}

.date-range-wrap {
  .ant-space {
    width: 100%;
  }
  .ant-picker {
    width: 100%;
    height: 50px;
    font-size: 16px;
    border-radius: 4px;
  }
}

.filter-label {
  font-size: 13px !important;
}

// ========================================= MEDIA QUERY =======================================

@media (max-width: 1440px) {
  .common-btn {
    font-size: 14px;
    padding: 0px 32px;
    height: 42px;
    line-height: 42px;
  }

  .card-title {
    font-size: 20px;
  }

  .actions-wrap ul li {
    margin-right: 10px;
  }

  .filter-wrapper .submit-filter {
    font-size: 14px;
    height: 42px;
  }

  .form-control {
    font-size: 14px !important;
    height: 42px;
  }

  p {
    font-size: 14px !important;
  }

  .outline-btn svg {
    position: relative;
    top: -2px;
  }

  .success-token {
    padding: 3px 20px;
  }

  .ant-table-thead > tr > th {
    padding: 12px 16px;
  }
  .sidebar-top-section {
    padding: 14px 22px;
  }
  .ant-layout .ant-layout-header {
    height: 49px;
    line-height: 49px;
  }

  .ant-menu-item a {
    font-size: 13px;
  }

  .ant-breadcrumb {
    margin-top: 65px !important;
  }

  .top-heading {
    margin-bottom: 25px;
  }

  .ant-layout .ant-layout-footer {
    font-size: 13px;
  }
  .ant-menu-item .active {
    font-size: 13px;
  }
  .view-icon {
    font-size: 18px;
  }
  .delete-icon {
    font-size: 18px;
  }
  .edit-icon {
    font-size: 18px;
  }
  .ant-select-selector {
    width: 100% !important;
    height: 42px !important;

    .ant-select-selection-item {
      line-height: 42px !important;
      font-size: 14px;
    }
    .ant-select-selection-search-input {
      height: 42px !important;
    }
    .ant-select-selection-placeholder {
      line-height: 42px !important;
    }
  }
  .date-range-wrap {
    .ant-picker {
      width: 100%;
      height: 42px;
      font-size: 14px;
    }
  }
  .react-datepicker__month-select {
    height: 25px;
  }
  .react-datepicker__year-select {
    height: 25px;
  }
}

// ===========================================END HERE=========================================

// =========================================LOGIN CSS==========================================

.mem-id-p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.login-box {
  .form-title {
    font-size: 32px;
    color: #412ea7;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

.send-otp-btn {
  background-color: red;
}

#login-form p {
  margin-bottom: 30px;
  color: #505050;
}

.err-msg {
  color: red !important;
  font-size: 13px !important;
}

.success-msg {
  color: green !important;
  font-size: 13px !important;
}

// ==============================================END HERE===========================================

// =========================================LOGIN CSS==========================================

.mem-id-p {
  font-size: 14px;
  font-weight: 600;
  text-align: center;
}

.login-box {
  .form-title {
    font-size: 32px;
    color: #412ea7;
    font-weight: 600;
    margin-bottom: 30px;
  }
}

.send-otp-btn {
  background-color: red;
}

#login-form p {
  margin-bottom: 30px;
  color: #505050;
}

// ==============================================END HERE===========================================
