.input-wrapper {
  .name_input {
    display: flex;
    justify-content: left;
    margin: 20px 0;
  }
  .firstname {
    margin: 0 30px 0 0;
  }
}

.main-wrapper {
  .form-row {
    margin-bottom: 20px;
  }
}
.member-wrapper {
  p {
    font-size: 16px;
  }
}
