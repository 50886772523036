@import 'react-toastify/dist/ReactToastify.css';

:root {
  --toastify-color-dark: #2c2c2c;
  --toastify-color-info: #1677ff;
  --toastify-color-success: #52c41a;
  --toastify-color-warning: #faad14;
  --toastify-color-error: #f5222d;
}

@import 'core/breadcrumb';
@import 'core/footer';
@import 'core/navbar';
@import 'core/sidebar';
@import 'core/suspense_loader';
@import 'core/template';
@import 'custom';
@import 'App';
